/**
 * This file is generated by scripts/svg.ts. Please don't modify manually.
 */

const svgs = {
  "add-post": require("./add-post.svg"),
  "align-center": require("./align-center.svg"),
  "align-left": require("./align-left.svg"),
  "align-right": require("./align-right.svg"),
  "archive": require("./archive.svg"),
  "arrow-down": require("./arrow-down.svg"),
  "arrow-hook": require("./arrow-hook.svg"),
  "arrow-left": require("./arrow-left.svg"),
  "arrow-right": require("./arrow-right.svg"),
  "arrow-up": require("./arrow-up.svg"),
  "assistant": require("./assistant.svg"),
  "asterisk": require("./asterisk.svg"),
  "bar-chart": require("./bar-chart.svg"),
  "beacon": require("./beacon.svg"),
  "bell-strikethrough": require("./bell-strikethrough.svg"),
  "bell": require("./bell.svg"),
  "bewizr-logo": require("./bewizr-logo.svg"),
  "bewizr": require("./bewizr.svg"),
  "blank-page": require("./blank-page.svg"),
  "bold": require("./bold.svg"),
  "bookmark": require("./bookmark.svg"),
  "breakpoint": require("./breakpoint.svg"),
  "building": require("./building.svg"),
  "calendar": require("./calendar.svg"),
  "camera": require("./camera.svg"),
  "challenge": require("./challenge.svg"),
  "channel": require("./channel.svg"),
  "chat": require("./chat.svg"),
  "check-circle": require("./check-circle.svg"),
  "check": require("./check.svg"),
  "chevron-down": require("./chevron-down.svg"),
  "chevron-left": require("./chevron-left.svg"),
  "chevron-right-circle": require("./chevron-right-circle.svg"),
  "chevron-right": require("./chevron-right.svg"),
  "chevron-up": require("./chevron-up.svg"),
  "circuit": require("./circuit.svg"),
  "click": require("./click.svg"),
  "clock": require("./clock.svg"),
  "code": require("./code.svg"),
  "cog": require("./cog.svg"),
  "column-chart": require("./column-chart.svg"),
  "comments": require("./comments.svg"),
  "connect": require("./connect.svg"),
  "content-page": require("./content-page.svg"),
  "converse-script": require("./converse-script.svg"),
  "copy": require("./copy.svg"),
  "create": require("./create.svg"),
  "cross-circle": require("./cross-circle.svg"),
  "cross": require("./cross.svg"),
  "database": require("./database.svg"),
  "deactivate": require("./deactivate.svg"),
  "debug": require("./debug.svg"),
  "disk": require("./disk.svg"),
  "donut-chart": require("./donut-chart.svg"),
  "double-check": require("./double-check.svg"),
  "double-chevron-left": require("./double-chevron-left.svg"),
  "double-chevron-right": require("./double-chevron-right.svg"),
  "doubleclick": require("./doubleclick.svg"),
  "download": require("./download.svg"),
  "duration-metric": require("./duration-metric.svg"),
  "email": require("./email.svg"),
  "empty": require("./empty.svg"),
  "equals": require("./equals.svg"),
  "error-full": require("./error-full.svg"),
  "exclamation": require("./exclamation.svg"),
  "external-incoming": require("./external-incoming.svg"),
  "external": require("./external.svg"),
  "eye-off": require("./eye-off.svg"),
  "eye": require("./eye.svg"),
  "feed": require("./feed.svg"),
  "filter": require("./filter.svg"),
  "finish-flag": require("./finish-flag.svg"),
  "flash": require("./flash.svg"),
  "font-larger": require("./font-larger.svg"),
  "font-smaller": require("./font-smaller.svg"),
  "funnel-metric": require("./funnel-metric.svg"),
  "gauge": require("./gauge.svg"),
  "gradient-ew": require("./gradient-ew.svg"),
  "gradient-nesw": require("./gradient-nesw.svg"),
  "gradient-ns": require("./gradient-ns.svg"),
  "gradient-nwse": require("./gradient-nwse.svg"),
  "gradient-senw": require("./gradient-senw.svg"),
  "gradient-sn": require("./gradient-sn.svg"),
  "gradient-swne": require("./gradient-swne.svg"),
  "gradient-we": require("./gradient-we.svg"),
  "gravatar": require("./gravatar.svg"),
  "grid": require("./grid.svg"),
  "group": require("./group.svg"),
  "h1": require("./h1.svg"),
  "h2": require("./h2.svg"),
  "handshake": require("./handshake.svg"),
  "headset": require("./headset.svg"),
  "help": require("./help.svg"),
  "hexagons": require("./hexagons.svg"),
  "home": require("./home.svg"),
  "if": require("./if.svg"),
  "image": require("./image.svg"),
  "index-page": require("./index-page.svg"),
  "info-full": require("./info-full.svg"),
  "info": require("./info.svg"),
  "ios-status-bar-center": require("./ios-status-bar-center.svg"),
  "ios-status-bar-left": require("./ios-status-bar-left.svg"),
  "ios-status-bar-right": require("./ios-status-bar-right.svg"),
  "iphone": require("./iphone.svg"),
  "italic": require("./italic.svg"),
  "kebab": require("./kebab.svg"),
  "lang.en": require("./lang.en.svg"),
  "lang.nl": require("./lang.nl.svg"),
  "line-chart": require("./line-chart.svg"),
  "linear-script": require("./linear-script.svg"),
  "link": require("./link.svg"),
  "list": require("./list.svg"),
  "location-enter": require("./location-enter.svg"),
  "location-exit": require("./location-exit.svg"),
  "lock": require("./lock.svg"),
  "login": require("./login.svg"),
  "logo": require("./logo.svg"),
  "logout": require("./logout.svg"),
  "map-pin": require("./map-pin.svg"),
  "map": require("./map.svg"),
  "menu-page": require("./menu-page.svg"),
  "message.error": require("./message.error.svg"),
  "message.pending": require("./message.pending.svg"),
  "message.read": require("./message.read.svg"),
  "message.redacted": require("./message.redacted.svg"),
  "message.sent": require("./message.sent.svg"),
  "metric": require("./metric.svg"),
  "minus-circle-stack": require("./minus-circle-stack.svg"),
  "minus-circle": require("./minus-circle.svg"),
  "move-ew": require("./move-ew.svg"),
  "move-ns": require("./move-ns.svg"),
  "move": require("./move.svg"),
  "node-exit": require("./node-exit.svg"),
  "node": require("./node.svg"),
  "offline": require("./offline.svg"),
  "ordered-list": require("./ordered-list.svg"),
  "orphan": require("./orphan.svg"),
  "p": require("./p.svg"),
  "page": require("./page.svg"),
  "palette": require("./palette.svg"),
  "paperclip": require("./paperclip.svg"),
  "participant": require("./participant.svg"),
  "paste": require("./paste.svg"),
  "pause-circle": require("./pause-circle.svg"),
  "pause": require("./pause.svg"),
  "pencil": require("./pencil.svg"),
  "pin": require("./pin.svg"),
  "plan:launchpad": require("./plan:launchpad.svg"),
  "plan:rocket": require("./plan:rocket.svg"),
  "plan:shuttle": require("./plan:shuttle.svg"),
  "plan:spaceship": require("./plan:spaceship.svg"),
  "plan:spacestation": require("./plan:spacestation.svg"),
  "planner-group": require("./planner-group.svg"),
  "planner-ungroup": require("./planner-ungroup.svg"),
  "planner": require("./planner.svg"),
  "play-circle-full": require("./play-circle-full.svg"),
  "play-circle-strikethrough": require("./play-circle-strikethrough.svg"),
  "play-circle": require("./play-circle.svg"),
  "play": require("./play.svg"),
  "plus-circle-stack": require("./plus-circle-stack.svg"),
  "plus-circle": require("./plus-circle.svg"),
  "plus": require("./plus.svg"),
  "pointer": require("./pointer.svg"),
  "post": require("./post.svg"),
  "programme-page": require("./programme-page.svg"),
  "puzzle": require("./puzzle.svg"),
  "qrcode": require("./qrcode.svg"),
  "question": require("./question.svg"),
  "reload": require("./reload.svg"),
  "reply": require("./reply.svg"),
  "reset-circle": require("./reset-circle.svg"),
  "reset": require("./reset.svg"),
  "robot": require("./robot.svg"),
  "rocket": require("./rocket.svg"),
  "sample-node": require("./sample-node.svg"),
  "satellite": require("./satellite.svg"),
  "search": require("./search.svg"),
  "signup": require("./signup.svg"),
  "sort-ascending": require("./sort-ascending.svg"),
  "sort-descending": require("./sort-descending.svg"),
  "sort-handle": require("./sort-handle.svg"),
  "splitter-node": require("./splitter-node.svg"),
  "star-empty": require("./star-empty.svg"),
  "star-full": require("./star-full.svg"),
  "star-half": require("./star-half.svg"),
  "star-input-empty": require("./star-input-empty.svg"),
  "star-input-full": require("./star-input-full.svg"),
  "star-input-half": require("./star-input-half.svg"),
  "star": require("./star.svg"),
  "stars": require("./stars.svg"),
  "step": require("./step.svg"),
  "stop-circle": require("./stop-circle.svg"),
  "stop": require("./stop.svg"),
  "stopwatch": require("./stopwatch.svg"),
  "switch-off": require("./switch-off.svg"),
  "switch-on": require("./switch-on.svg"),
  "tag": require("./tag.svg"),
  "target": require("./target.svg"),
  "text": require("./text.svg"),
  "trash": require("./trash.svg"),
  "triangle-down": require("./triangle-down.svg"),
  "triangle-up": require("./triangle-up.svg"),
  "trophy": require("./trophy.svg"),
  "unlock": require("./unlock.svg"),
  "unordered-list": require("./unordered-list.svg"),
  "upload": require("./upload.svg"),
  "user": require("./user.svg"),
  "valued-metric": require("./valued-metric.svg"),
  "video": require("./video.svg"),
  "warning-full": require("./warning-full.svg"),
  "web": require("./web.svg"),
  "webcast": require("./webcast.svg"),
  "worker": require("./worker.svg"),
  "zoom-fit": require("./zoom-fit.svg"),
  "zoom-in": require("./zoom-in.svg"),
  "zoom-out": require("./zoom-out.svg"),
  "zoom-reset": require("./zoom-reset.svg"),
}

export default svgs