import {
  DraftBlockType,
  DraftInlineStyleType,
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntity,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from 'draft-js'
import { isArray, isFunction } from 'lodash'
import { ASTNode, ParserRules, SingleASTNode, State } from 'simple-markdown'
import { UnicodeString } from 'unicode'
import { Scope } from './types'

export default class MarkdownToDraftJSBuilder {

  constructor(
    public readonly rules: ParserRules,
    public readonly scope: Scope,
    public readonly state: State,
  ) {}

  public currentKey: number = 0

  //------
  // Build

  public buildState(tree: ASTNode): RawDraftContentState {
    this.visitNode(tree)
    if (this.currentBlock != null) {
      this.endBlock()
    }

    return {
      blocks:    this.blocks,
      entityMap: this.entityMap,
    }
  }

  private visitNode(node: ASTNode | null | undefined) {
    if (node == null) { return }

    if (isArray(node)) {
      node.forEach(it => this.visitNode(it))
      return
    }

    const rule       = this.rules[node.type]
    const ruleOutput = (rule as any)?.draftjs
    const method     = (this as any)[`visit_${node.type}`]

    if (isFunction(ruleOutput)) {
      ruleOutput(node, this, this.state)
    } else if (isFunction(method)) {
      method.call(this, node)
    } else {
      console.warn(`Unknown node type: ${node.type}`)
    }
  }

  private visit_paragraph(node: SingleASTNode) {
    this.appendBlock('paragraph', node.content)
  }

  private visit_text(node: SingleASTNode) {
    // This is the escape character. Skip it.
    if (node.content === '\\') { return }

    this.appendText(node.content)
  }

  private visit_heading(node: SingleASTNode) {
    const style =
      node.level === 1 ? 'header-one' :
      node.level === 2 ? 'header-two' :
      node.level === 3 ? 'header-three' :
      'header-four'

    this.appendBlock(style, node.content)
  }

  private visit_em(node: SingleASTNode) {
    this.appendInline('ITALIC', node.content)
  }

  private visit_strong(node: SingleASTNode) {
    this.appendInline('BOLD', node.content)
  }

  private visit_inlineCode(node: SingleASTNode) {
    this.appendInline('CODE', node.content)
  }

  private visit_link(node: SingleASTNode) {
    this.appendInlineEntity({
      type:       'LINK',
      mutability: 'MUTABLE',
      data:       {url: node.target},
    }, node.content)
  }

  private visit_image(node: SingleASTNode) {
    this.appendBlockOrInlineEntity({
      type:       'IMAGE',
      mutability: 'IMMUTABLE',
      data:       {
        src:   node.target,
        alt:   node.alt,
        title: node.title,
        style: node.style ?? {},
      },
    }, node.content)
  }

  private visit_list(node: SingleASTNode) {
    const type = `${node.ordered ? 'ordered' : 'unordered'}-list-item`
    node.items.forEach((item: any) => {
      this.appendBlock(type, item)
    })
  }

  private visit_newline(node: SingleASTNode) {
    this.appendText('\n')
  }

  private visit_br(node: SingleASTNode) {
    this.appendText('\n')
  }

  private visit_escape() {
  }

  //------
  // Blocks

  public readonly blocks: RawDraftContentBlock[] = []

  private currentBlock: RawDraftContentBlock | null = this.scope === 'inline' ? {
    key:               `${this.currentKey++}`,
    type:              'paragraph',
    depth:             0,
    text:              '',
    inlineStyleRanges: [],
    entityRanges:      [],
  } : null

  private entityMap: Record<string, RawDraftEntity> = {}

  private currentOffset: number = 0

  public appendBlock(type: DraftBlockType, content: ASTNode | string) {
    this.startBlock(type)
    if (typeof content === 'string') {
      this.appendText(content)
    } else {
      this.visitNode(content)
    }
    this.endBlock()
  }

  private startBlock(type: DraftBlockType) {
    if (this.scope === 'inline') { return }

    this.currentBlock = {
      key:               `${this.currentKey++}`,
      type:              type,
      depth:             0,
      text:              '',
      inlineStyleRanges: [],
      entityRanges:      [],
    }
    this.currentOffset = 0
  }

  public endBlock() {
    if (this.currentBlock == null) { return }

    this.blocks.push(this.currentBlock)
    this.currentBlock = null
  }

  public appendText(text: string | UnicodeString) {
    if (this.currentBlock == null) { return }

    const string = new UnicodeString(text)

    this.currentBlock.text += string.string
    this.currentOffset += string.length
  }

  //------
  // Inline ranges

  private inlineRangeStack: RawDraftInlineStyleRange[] = []

  public appendInline(type: DraftInlineStyleType, content: ASTNode | string) {
    this.startInlineRange(type)
    if (typeof content === 'string') {
      this.appendText(content)
    } else {
      this.visitNode(content)
    }
    this.endInlineRange()
  }

  public startInlineRange(style: DraftInlineStyleType) {
    this.inlineRangeStack.push({
      style:  style,
      offset: this.currentOffset,
      length: 0,
    })
  }

  public endInlineRange() {
    if (this.currentBlock == null) { return }

    const range = this.inlineRangeStack.pop()
    if (range == null) { return }

    this.currentBlock.inlineStyleRanges.push({
      style:  range.style,
      offset: range.offset,
      length: this.currentOffset - range.offset,
    })
  }

  //------
  // Entities

  private entityRangeStack: RawDraftEntityRange[] = []

  public appendBlockOrInlineEntity(type: RawDraftEntity, content: ASTNode | string) {
    if (this.scope === 'inline' || (this.currentBlock != null && this.currentBlock.text.length > 0)) {
      this.appendInlineEntity(type, content)
    } else {
      this.appendBlockEntity(type, content)
    }
  }

  public appendBlockEntity(type: RawDraftEntity, content: ASTNode | string) {
    this.startBlock('atomic')
    this.appendInlineEntity(type, content)
    this.endBlock()
  }

  public appendInlineEntity(entity: RawDraftEntity, content: ASTNode | string) {
    this.startEntityRange(entity)
    if (content == null) {
      this.appendText(' ')
    } else if (typeof content === 'string') {
      this.appendText(content)
    } else {
      this.visitNode(content)
    }
    this.endEntityRange()
  }

  public startEntityRange(entity: RawDraftEntity) {
    const key = this.currentKey++

    this.entityRangeStack.push({
      key:    key,
      offset: this.currentOffset,
      length: 0,
    })
    this.entityMap[key] = entity
  }

  public endEntityRange() {
    if (this.currentBlock == null) { return }

    const range = this.entityRangeStack.pop()
    if (range == null) { return }

    this.currentBlock.entityRanges.push({
      key:    range.key,
      offset: range.offset,
      length: this.currentOffset - range.offset,
    })
  }

}